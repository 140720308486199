import React from 'react';
import ContactsForm from "../ContactsInfo/ContactsForm";
import Title from "../Common/Title";

const FeedbackForm = () => (
  <div className="panel my-5">
    <div className="container">
      <div className="mb-3">
        <Title position="left">Залишити відгук</Title>
      </div>
      <ContactsForm/>

      <div className="mb-3">
        <Title position="left">Контактні телефоні</Title>
      </div>
      <p className="fs-2">+38 (073) 016-08-19</p>
      <p className="fs-2">+38 (068) 518-63-18</p>
    </div>
  </div>
);

export default FeedbackForm;